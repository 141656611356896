import React, { useState, useEffect } from 'react';


function GodaddyIdentifier() {

  return (
    <>
      <h1>dcq1krbeffk0fsc8huj0g2jmsv</h1>
    </>
  );
}

export default GodaddyIdentifier;
